import './scss/index.scss';

import 'slick-carousel';
import 'bootstrap/js/tooltip';
import * as moment from 'moment-timezone';
import {
    slugify,
    scrollToElement,
    addCommasToNumber,
} from '../../shared/utils';
import {MuiForm} from "../shared/components/mui-form/mui-form";


$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();

    // help page
    $('#about_faq a[data-toggle="collapse"]').click(function() {
        const element = $(this);

        if (!element.attr('data-clicked')) {
            const faqId = slugify(element.text());

            if (faqId.length) {
                window.Analytics.track('38|0h. Help Request', {
                    type_id: 277,
                    eventData: {
                        "event_cat_id": 38,
                        "event_cat_name": "0h. Help Request",
                        "tl_event_id": 277,
                        "tl_event_name": "/help FAQ clicked",
                        "tl_event_data": faqId
                    },
                    localData: {
                        datavar: faqId
                    }
                });
            }

            element.attr('data-clicked', true);
        }
    });

    // pricing page
    $('#marketing_pricing a[data-toggle="collapse"]').click(function() {
        const element = $(this);

        if (!element.attr('data-clicked')) {
            const faqId = slugify(element.text());

            if (faqId.length) {
                window.Analytics.track('44|0a. Funnel uppath', {
                    type_id: 220,
                    eventData: {
                        "event_cat_id": 44,
                        "event_cat_name": "0a. Funnel uppath",
                        "tl_event_id": 220,
                        "tl_event_name": "2b. /pricing FAQ clicked",
                        "tl_event_data": faqId
                    },
                    localData: {
                        datavar: faqId
                    }
                });
            }

            element.attr('data-clicked', true);
        }
    });

    // on marketing page
    $('.read-more__open').click(function() {
        const rdMoreId = $(this).attr('data-event-type');
        window.Analytics.track('14|0a. Marketing', {
            type_id: 276,
            eventData: {
                "event_cat_id": 14,
                "event_cat_name": "0a. Marketing",
                "tl_event_id": 276,
                "tl_event_name":"/w/membership - read more",
                "tl_event_data": rdMoreId
            },
            localData: {
                datavar: rdMoreId,
                force_log: true
            }
        });
    });

    // membership CTA
    $('[data-membership-cta-id]').click(function() {
        const ctaId = $(this).attr('data-membership-cta-id');
        window.Analytics.track('14|0a. Marketing', {
            type_id: 300,
            eventData: {
                "event_cat_id": 14,
                "event_cat_name": "0a. Marketing",
                "tl_event_id": 300,
                "tl_event_name":"/w/membership - cta",
                "tl_event_data": ctaId
            },
            localData: {
                datavar: ctaId,
                force_log: true
            }
        });
    });

    $('[data-show="element"]').click(function(event) {
        event.preventDefault();
        $(this.getAttribute('data-target')).css('display', 'flex');
    });

    $('[data-hide="element"]').click(function(event) {
        event.preventDefault();
        $(this.getAttribute('data-target')).css('display', 'none');
    });

    $('[data-scroll-to]').click(event => {
        event.preventDefault();
        const destSelector = $(event.target).attr('data-scroll-to');
        scrollToElement($(destSelector), 500);
    });

    if ($('#marketing_pricing_v2').length > 0 || $('#marketing_pricing_v3').length > 0) {
        const ETHICS_FEE_COST = 15;
        let planId = null;
        let isAnnually = $('input[name=is_yearly]:checked').val() === "1";

        MuiForm.init();

        function updatePaymentForm() {
            if (!planId && !window.is_logged_in) {
                return;
            }

            const $th = $('[data-plan-id=' + planId + ']');
            const planPrice = parseInt($th.attr('data-price-month'));
            const planPriceAnnual = parseInt($th.attr('data-price-year'));
            const planTotalAnnual = planPriceAnnual * 12;

            let planPriceText = '<span class="tl-font-family-base">$</span>';
            if (isAnnually) {
                planPriceText += planPriceAnnual + ' / month &mdash; ';
                planPriceText += 'paid annually (<span class="tl-font-family-base">$</span>' + addCommasToNumber(planTotalAnnual) + ' / year)';
            } else {
                planPriceText += planPrice + ' / month &mdash; ';
                planPriceText += 'paid month-to-month';
            }

            $('#membership_name').text($th.attr('data-plan-title') + ' Membership');
            $('#membership_type').text(isAnnually ? 'Annual' : 'Monthly');
            $('#membership_price').html(planPriceText);
            $('#ethics_fee_cost').text(ETHICS_FEE_COST.toString());
            $('#start_date').text(moment().format('MMM D, YYYY'));
            $('#end_date').text(moment().add(1, 'y').subtract(1, 'days').format('MMM D, YYYY'));

            if (isAnnually) {
                $('#membership_price_short').html(planPriceAnnual);
                $('#monthly_total').text(addCommasToNumber(planPriceAnnual + ETHICS_FEE_COST));
                $('#12_months_total_amount').html(addCommasToNumber((planPriceAnnual + ETHICS_FEE_COST) * 12));
                $('#12_months_total').css({ display: 'block' });
                $('#membership_total_price').html(addCommasToNumber(planTotalAnnual));
            } else {
                $('#membership_price_short').html(planPrice);
                $('#monthly_total').text(addCommasToNumber(planPrice + ETHICS_FEE_COST));
                $('#12_months_total').css({ display: 'none' });
                $('#membership_total_price').html(addCommasToNumber(planPrice));
            }
        }

        $('input[name=is_yearly]').change(event => {
            isAnnually = event.target.value === "1";

            $('[data-plan-id]').each((index, element) => {
                const id = element.getAttribute('data-plan-id');
                document.getElementById('plan_price_' + id).innerText = element.getAttribute(isAnnually ? 'data-price-year' : 'data-price-month');
            });

            updatePaymentForm();
        });

        if (window.is_logged_in) {
            $('[data-selected-plan-id]').click(event => {
                event.preventDefault();
                planId = event.target.getAttribute('data-selected-plan-id');
                updatePaymentForm();
                $('#payment_form').slideDown();
                scrollToElement($('#payment_form'), 1000);
            });
        }
    }

    if ($('#about_faq').length > 0) {
        if (location.hash !== null && location.hash !== "" && $(location.hash + ".collapse").length > 0) {
            $(location.hash + ".collapse").collapse("show");
            setTimeout(() => {
                const destId = location.hash.replace('_collapse', '');
                $('html').scrollTop($(destId).offset().top);
            }, 250);
        }
    }

    $('.reviews-slider').slick({
        autoplay: true,
        infinite: true,
        arrows: false,
        dots: true
    });
    
});