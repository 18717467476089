import {
    doPatternTestFromElement,
    getPatternMessageFromElement,
    isPassword,
    doEmailPatternTest,
    checkWebsite
} from "../../../../shared/utils";

export const MuiForm = (function() {
    const exports = {};

    exports.showError = function(message) {
        if ($('.tl-mui-form .tl-mui-form__error').length <= 0) {
            $('.tl-mui-form').append('' +
                '<div class="tl-mui-form__error">' +
                '   <i class="fas fa-exclamation-circle tl-text--warning"></i> <span class="tl-mui-form__error-text">' + message + '</span>' +
                '</div>');
        } else {
            $('.tl-mui-form .tl-mui-form__error-text').text(message);
            $('.tl-mui-form .tl-mui-form__error').show();
        }
    };

    exports.hideError = function() {
        $('.tl-mui-form .tl-mui-form__error').hide();
    };

    exports.showLoading = function(message) {
        if ($('.tl-mui-form .tl-mui-form__loading').length <= 0) {
            const loadingMessage = message ? message : 'Saving changes.';
            $('.tl-mui-form').append('' +
                '<div class="tl-mui-form__loading">' +
                '   <i class="fas fa-spinner fa-spin"></i> <span class="tl-mui-form__loading-text">' + loadingMessage + '</span>' +
                '</div>');
        } else {
            $('.tl-mui-form .tl-mui-form__loading').show();
        }
    };

    exports.hideLoading = function() {
        $('.tl-mui-form .tl-mui-form__loading').hide();
    };

    exports.showSuccessMessage = function(message) {
        if ($('.tl-mui-form .tl-mui-form__success').length <= 0) {
            $('.tl-mui-form').append('' +
                '<div class="tl-mui-form__success">' +
                '   <i class="fas fa-check tl-text--success"></i> <span class="tl-mui-form__success-text">' + message + '</span>' +
                '</div>');
        } else {
            $('.tl-mui-form .tl-mui-form__success-text').text(message);
            $('.tl-mui-form .tl-mui-form__success').show();
        }
    };

    exports.hideSuccessMessage = function() {
        $('.tl-mui-form .tl-mui-form__success').hide();
    };

    exports.lockSubmit = function() {
        $('.tl-mui-form button[type=submit]').attr('disabled', true);
    };

    exports.unlockSubmit = function() {
        $('.tl-mui-form button[type=submit]').attr('disabled', false);
    };

    exports.showFieldError = function($element, message) {
        const $parent = $element.parent('.tl-mui-field');

        $parent.addClass('tl-mui-field--error');
        $parent.find('.tl-mui-field__input').data('isValid', false);

        if ($parent.find('.tl-mui-field__error').length > 0) {
            $parent.find('.tl-mui-field__error').text(message);
        } else {
            $parent.append('<small class="tl-mui-field__error">' + message + '</small>');
        }

        return true;
    }

    exports.checkFieldInError = function($element) {
        const $me = $element;
        let isInError = false;

        if (!isInError) {
            if ($me.attr('minlength') && $me.val().length < $me.attr('minlength')) {
                isInError = this.showFieldError($me, 'Minimum characters number is ' + $me.attr('minlength') + '.');
            }
        }

        if (!isInError) {
            if ($me.attr('maxlength') && $me.val().length > $me.attr('maxlength')) {
                isInError = this.showFieldError($me, 'Maximum characters number is ' + $me.attr('maxlength') + '.');
            }
        }

        if (!isInError) {
            if ($me.attr('data-pattern') && !doPatternTestFromElement($me)) {
                isInError = this.showFieldError($me, getPatternMessageFromElement($me));
            }
        }

        if (!isInError) {
            if ($me.data('type') === 'password' && !isPassword($me.val())) {
                isInError = this.showFieldError($me, 'Password does not meet the requirements.');
            }
        }

        if (!isInError) {
            if ($me.data('type') === 'email' && !doEmailPatternTest($me.val())) {
                isInError = this.showFieldError($me, 'Email is incorrect.');
            }
        }

        if (!isInError) {
            if ($me.attr('required') && $me.val() === '') {
                isInError = this.showFieldError($me, 'This field is required.');
            }
        }

        return isInError;
    }

    exports.checkPasswordFields = function() {
        const pass1 = $('.tl-mui-form').find('input[name=password]').val();
        const pass2 = $('.tl-mui-form').find('input[name=password_confirm]').val();

        if (pass1 === '' || pass2 === '') {
            this.showError('Please fill both fields.');
            return false;
        }

        if (!$('.tl-mui-form').find('input[name=password]').data('isValid') || !$('.tl-mui-form').find('input[name=password_confirm]').data('isValid')) {
            this.hideError();
            return false;
        }

        if (pass1 !== pass2) {
            this.showError('Passwords do not match.');
            return false;
        }

        this.hideError();
        return true;
    };

    exports.init = function($container) {
        if (!$container) {
            $container = $('.tl-mui-form');
        }

        $container.find('input[class="tl-mui-field__input"]')
            .each((index, element) => {
                const $me = $(element);

                if (!!$me.val()) {
                    $me.parent('.tl-mui-field').addClass('tl-mui-field--focus');
                }

                if ($me.attr('type') === 'password') {
                    $me.parent('.tl-mui-field').append('<span class="far fa-eye tl-mui-field__icon tl-mui-field__show-password"></span>');
                    $me.data('type', 'password');
                } else if ($me.attr('type') === 'url') {
                    $me.parent('.tl-mui-field').addClass('tl-mui-field--has-icon');
                }

                if ($me.attr('readonly')) {
                    $me.parent('.tl-mui-field').addClass('tl-mui-field--readonly');
                }
            })
            .focusin(event => {
                $(event.target).parent('.tl-mui-field').addClass('tl-mui-field--focus');
            })
            .focusout(event => {
                const $me = $(event.target);

                if ($me.val() === '') {
                    $me.parent('.tl-mui-field').removeClass('tl-mui-field--focus');
                }

                if(MuiForm.checkFieldInError($me)) {
                    return;
                }

                $me.parent('.tl-mui-field').removeClass('tl-mui-field--error');
                $me.data('isValid', true);
            })
            .bind("input", event => { // will do keyup and paste events
                const $me = $(event.target);

                if(MuiForm.checkFieldInError($me)) {
                    return;
                }

                $me.parent('.tl-mui-field').removeClass('tl-mui-field--error');
                $me.data('isValid', true);

                MuiForm.hideError();
            });

        $container.find('select.tl-mui-field__input')
            .each((index, element) => {
                $(element).parent('.tl-mui-field').addClass('tl-mui-field--focus');
            });

        $container.find('textarea.tl-mui-field__input')
            .each((index, element) => {
                const $me = $(element);

                if ($me.val() !== '') {
                    $me.parent('.tl-mui-field').addClass('tl-mui-field--focus');
                }

                if ($me.attr('maxlength')) {
                    $me.parent('.tl-mui-field').append('' +
                        '<span class="tl-mui-field__counter">' +
                        '<span class="tl-mui-field__counter-value">' + $me.val().length + '</span>' +
                        ' / ' + $me.attr('maxlength') +
                        '</span>');
                }
            })
            .focusin(event => {
                $(event.target).parent('.tl-mui-field').addClass('tl-mui-field--focus');
            })
            .focusout(event => {
                const $me = $(event.target);

                if ($me.val() === '') {
                    $me.parent('.tl-mui-field').removeClass('tl-mui-field--focus');
                }

                if ($me.attr('required') && $me.val() === '') {
                    this.showFieldError($me, 'This field is required.');
                    return;
                }

                $me.parent('.tl-mui-field').removeClass('tl-mui-field--error');
                $me.data('isValid', true);
            })
            .bind("input", event => {
                const $me = $(event.target);

                if ($me.attr('maxlength')) {
                    $me.parent('.tl-mui-field').find('.tl-mui-field__counter-value').text($me.val().length);
                }

                if ($me.attr('required') && $me.val() === '') {
                    this.showFieldError($me, 'This field is required.');
                    return;
                }

                $me.parent('.tl-mui-field').removeClass('tl-mui-field--error');
                $me.data('isValid', true);
            });

        $container.find('input.tl-mui-field__input[type="url"]').blur(event => {
            var $me = $(event.target);
            var URL = $me.val();
            var lURL = URL.toLowerCase();

            if (lURL === '' || lURL === 'none') {
                $me.parent('.tl-mui-field').find('.tl-mui-field__icon').remove();
                return;
            }

            $me.parent('.tl-mui-field').find('.tl-mui-field__icon').remove();
            $me.parent('.tl-mui-field').append('<span class="tl-mui-field__icon"><i class="fas fa-spinner fa-spin"></i></span>');


            checkWebsite(URL, function() {
                $me.parent('.tl-mui-field').find('.tl-mui-field__icon').remove();
                $me.parent('.tl-mui-field').append('<span class="tl-mui-field__icon"><i class="fas fa-check tl-color--wtb"></i></span>');
            }, function() {
                $me.parent('.tl-mui-field').find('.tl-mui-field__icon').remove();
                $me.parent('.tl-mui-field').append('<span class="tl-mui-field__icon"><i class="fas fa-times tl-color--primary"></i></span>');
                MuiForm.showFieldError($me, 'Your website is not working. Did you type it correctly?');
            });
        });

        $container.find('.tl-mui-field__show-password').click(event => {
            event.preventDefault();

            const $me = $(event.target);
            const $input = $me.parents('.tl-mui-field').find('.tl-mui-field__input');

            if ($input.attr('type') === 'password') {
                $input.attr('type', 'text');
                $me.removeClass('fa-eye').addClass('fa-eye-slash');
            } else {
                $input.attr('type', 'password');
                $me.removeClass('fa-eye-slash').addClass('fa-eye');
            }
        });
    };

    return exports;
})();